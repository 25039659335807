.button {
  position: relative;
  display: inline-block;
  @include fontsize(34);
  line-height: 1;
  @include fontb();
  text-decoration: none;
  cursor: pointer;
  .button_inner {
    position: relative;
    display: block;
    padding: 20px;
    padding-right: 80px;
    background-color: #ffffff;
    color: #9742b7;
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s;
    border-radius: 10px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    // &:before{
    //     display: block;
    //     position: absolute;
    //     left: 0;
    //     top:50%;
    //     @include translatecentery();
    //     width: 46px;
    //     height: 100%;
    //     @include backgroundimage('arrow_right.svg');
    //     background-size: 100% 100%;
    //     background-position: 0 50%;
    //     background-repeat: no-repeat;
    // }
    &.multi_line {
      white-space: normal;
      overflow: visible;
      text-overflow: initial;
    }
  }
  &:hover {
    .button_icon {
      background-position: top;
    }
  }
  .button_icon {
    position: absolute;
    top: 50%;
    right: 10px;
    height: 60px;
    width: 60px;
    @include translatecentery();
    -webkit-transition: -webkit-transform 0.3s $superbounce;
    transition: transform 0.3s $superbounce;
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 60px;

    &--svg-modifier {
      background-size: 60px 120px;
    }

    &.videos {
      @include backgroundimage('icons/videos_purple.svg');
    }
    &.games {
      @include backgroundimage('icons/games_purple.svg');
    }
  }

  &--pink {
    .button_inner {
      background-color: $color_pink;
    }
  }
  &--darkpurple {
    .button_inner {
      background-color: darken($color_purple, 15%);
    }
  }
  &.button--small {
    @include fontsize(18);
    line-height: 1;
    .button_inner {
      padding-left: 34px;
      padding-right: 14px;
      &:before {
        width: 27px;
      }
    }
  }
  &.active {
    .button_inner {
      //background-color: $color_pink;
    }
  }

  &.default-cursor {
    cursor: default;
    .button_icon {
      @include translatecentery(!important);
    }
  }

  &.disabled {
    opacity: 0.2;
    cursor: default;
  }

  &--align-top {
    vertical-align: top;
  }

  &.games_holder {
    .button_icon {
      @include backgroundimage('icons/games_purple.svg');
    }
    &:hover {
      .button_icon {
        @include backgroundimage('icons/games.svg');
      }
    }
  }
  &.videos_holder {
    .button_icon {
      @include backgroundimage('icons/videos_purple.svg');
    }
    &:hover {
      .button_icon {
        @include backgroundimage('icons/videos.svg');
      }
    }
  }
}

[dir='rtl'] {
  .button {
    text-align: right;
    direction: rtl;
    .button_inner {
      &:before {
        left: auto;
        right: 0;
        @include backgroundimage('arrow_left.svg');
      }
    }
    .button_icon {
      left: auto;
      right: 0;
    }

    &.button--small {
      .button_inner {
        padding-left: 14px;
        padding-right: 34px;
      }
    }
  }
}

.buttons {
  font-size: 0;
  direction: ltr;
  /* text-align: center; */
  .wrapper {
    padding: 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .button {
    width: auto;
    margin-bottom: 10px;
    /* text-align: left; */
    &:nth-child(odd) {
      padding-right: 10px;
      padding-left: 10px;
    }
    &:nth-child(even) {
      padding-right: 10px;
      padding-left: 10px;
    }
  }
}

.no-touchevents {
  .button--404:hover {
    text-decoration: none !important;
    .button_inner {
      background-color: #9742b7;
      background-image: none;
    }
  }
  .button:hover {
    .button_icon {
      @include translatecentery(rotate(-10deg));
      &.videos {
        @include backgroundimage('icons/videos.svg');
      }
      &.games {
        @include backgroundimage('icons/games.svg');
      }
    }
    .button_inner {
      background-color: #9742b7;
      color: #ffffff;
    }
  }
}

@media (max-width: 767px) {
  .button {
    @include fontsize(math.div(48, 2));
    line-height: 1;
    margin-bottom: math.div($sectionMargin, 2);
    .button_inner {
      padding-left: 50px;
      padding-right: 65px;
      &:before {
        width: 36px;
        height: 42px;
      }
    }
    .button_icon {
    }
  }
  [dir='rtl'] {
    .button {
      .button_inner {
        padding-left: 50px;
        padding-right: 65px;
      }
    }
  }
  .buttons {
    .button {
      width: 100%;
      &:nth-child(odd) {
        padding-right: 0;
        padding-left: 0;
      }
      &:nth-child(even) {
        padding-right: 0;
        padding-left: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

@media (max-width: 479px) {
  .button {
    .button_inner {
      padding-left: 20px;
      padding-right: 65px;
    }
  }
}

.hero_image {
  .button {
    @include fontsize(24);
  }
}

.button--404 {
  position: relative;
  display: inline-block;
  @include fontsize(40);
  line-height: 1;
  @include fontb();
  text-transform: none;
  text-decoration: none;
  cursor: pointer;

  margin: {
    right: 1%;
    left: 1%;
  }

  .button_inner {
    position: relative;
    display: block;
    padding: 10px;
    padding-right: 20px;
    color: #ffffff;
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s;
    padding-left: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-decoration: none;

    background-color: $color_purple;

    background: {
      position: top right;
      repeat: no-repeat;
      size: cover;
    }
    border-radius: 10px;

    &.multi_line {
      white-space: normal;
      overflow: visible;
      text-overflow: initial;
    }
  }
}

@media (max-width: 479px) {
  .button--404 {
    @include fontsize(20);
  }
}
