// Used by the Feedblocks

@import 'carousel_widget';

.carousel_widget.swiper {
  .carousel_item {
    //width: 30.33%;
    //width: -webkit-calc(33.33% - 2%);
    //width: calc(33.33% - 2%);
    margin-right: 2%;
  }
  .nav_button {
    cursor: pointer;
    height: 70px;
    width: 70px;
    position: absolute;
    top: 32%;
    background: #ffffff;
    background-size: 200%;
    background-repeat: no-repeat;
    @include carouselArrowsInActive();
    &.nav_left {
      left: 0px;
      @include backgroundimage('icons/arrows.svg');
      border-top-right-radius: 15px;
      border-bottom-right-radius: 15px;
      background-position: 0 100%;
      transform: translateX(-100%);
    }
    &.nav_right {
      right: 0px;
      @include backgroundimage('icons/arrows.svg');
      border-top-left-radius: 15px;
      border-bottom-left-radius: 15px;
      background-position: 0 0%;
      transform: translateX(100%);
    }
    &.disabled {
      display: none;
    }
    &.swiper-button-disabled {
      display: none;
    }
  }
}
html[dir='rtl'] {
  .carousel_widget.swiper {
    .nav_button {
      &.nav_left {
        left: 0;
      }
      &.nav_right {
        right: 15px;
      }
    }
  }
}
html[dir='rtl'] .carousel_widget.swiper .carousel_item {
  //margin-right: 1.6%;
  margin-right: 0;
  margin-left: 1.6%;
}

.carousel_widget.swiper {
  .carousel_item {
    //display: inline-block;
    vertical-align: top;
  }
  .nav_button:hover {
    //background-size: 45% auto;
    &.nav_left {
      background-position: 100% 100%;
      background-color: #9742b7;
    }
    &.nav_right {
      background-position: 100% 0%;
      background-color: #9742b7;
    }
  }
  &:hover .nav_button {
    @include carouselArrowsActive();
    &.disabled {
      display: none;
    }
    &.swiper-button-disabled {
      display: none;
    }
    &.show.disabled {
      display: none;
    }
  }
  .nav_button.show {
    @include carouselArrowsActive();
    &.disabled {
      display: none;
    }
    &.swiper-button-disabled {
      display: none;
    }
  }
}
//Show arrows on mobile touch
.show_all_carousel_nav_buttons section .carousel_widget.swiper .nav_button {
  @include carouselArrowsActive();
}
section:active .carousel_widget.swiper .nav_button {
  &.disabled {
    display: none;
  }
  &.swiper-button-disabled {
    display: none;
  }
  &.show.disabled {
    display: none;
  }
}
@media (max-width: 767px) {
  .carousel_widget.swiper:not(.app-screenshots__list) {
    .swiper-slide {
      margin-bottom: 20px;
    }
  }
}
