
.cookie_popup {
	width: 100%;
	z-index: 999999;
	position: fixed;
	bottom: 0;
	padding: 1%;
	background-color: $color_purple;
	color: #ffffff;
	box-shadow: 0px 0px 17px rgba(0, 0, 0, 0.2);
	.inner {
		max-width: 1024px;
		width: 100%;
		margin: 0 auto;
		display: flex;
		flex-wrap: wrap;
		@media only screen and (min-width: 640px) {
			display: block;
		}
	}
	.text {
		width: 100%;
		display: inline-block;
		vertical-align: middle;
		padding: 0 5%;
		margin-bottom: 10px;
		@media only screen and (min-width: 640px) {
			width: 70%;
		}
	}
	.buttons {
		width: 100%;
		display: inline-block;
		vertical-align: middle;
		text-align: center;
		@media only screen and (min-width: 640px) {
			width: 30%;
		}
	}
	a {
		background-color: #ffffff;
		color: #9742b7;
		border-radius: 10px;
		padding: 4px;
		font-size: 2.2rem;
		line-height: 1.1;
		display: inline-block;
		margin: 2px 4px;
		@include fontbl();
		padding-left: 10px;
		padding-right: 10px;
	}
	h5 {
		font-size: 2.4rem;
	}

	p {
		font-size: 1.8rem;
	}
}

.no-touchevents{
	.cookie_popup {
		a:hover{
			background-color: #cc3366;
			color: #ffffff;
		}
	}
}

[dir="rtl"]{

	.cookie_popup {
		a {
			direction: rtl;
		}
	}

}