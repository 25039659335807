$new_style_color_blue: #0066ff;

html.new-style {
  #letscreate_app {
    padding-bottom: 100px;
  }
  #letscreate_app .wrapper.b_purple {
    background-color: #fff;
  }

  .step-count {
    @include backgroundimage('new-style/letscreate/step_01.png');
    background-size: 80% auto;
  }

  .step-count--step2 {
    @include backgroundimage('new-style/letscreate/step_02.png');
  }

  .step-count--step3 {
    @include backgroundimage('new-style/letscreate/step_03.png');
  }

  .step-count--step4 {
    @include backgroundimage('new-style/letscreate/step_04.png');
  }

  .step-count--step5 {
    @include backgroundimage('new-style/letscreate/step_05.png');
  }

  #letscreate_app .form-toggle--active .form-toggle__colour,
  #letscreate_app .form-toggle--active div {
    fill: $new_style_color_blue;
    color: $new_style_color_blue;
  }

  .tooltip-wrapper__header p {
    color: $new_style_color_blue;
  }

  .expand-tooltip {
    background-color: $new_style_color_blue;
  }

  .upload-tooltip__title {
    color: $new_style_color_blue;
  }

  .upload-tooltip__examples__example figcaption p {
    color: $new_style_color_blue;
  }

  .submitform .description {
    color: $new_style_color_blue;
  }

  #letscreate_app .photo-panel .photo-buttons .photo-button {
    background-color: $new_style_color_blue;
  }

  .photo-buttons > .button {
    background-color: $new_style_color_blue;
    color: #fff;
    border: 2px solid $new_style_color_blue;
  }

  .photo-buttons > .button button {
    color: #fff;
  }

  .consent-form p {
    color: $new_style_color_blue;
  }

  .consent-form__list li span {
    color: $new_style_color_blue;
  }

  #letscreate_app .button_inner,
  #letscreate_app .wall .button_inner {
    background-color: $new_style_color_blue;
  }

  #letscreate_app .button:hover .button_inner {
    background-color: lighten($new_style_color_blue, 20%);
  }

  .submitform .description {
    color: $new_style_color_blue;
  }

  #letscreate_app .submitform__disclaimer,
  #letscreate_app .submitform__row label:not(.submitform__checkbox),
  #letscreate_app .submitform__row p,
  #letscreate_app .submitform__row span:not(.button_inner) {
    color: $new_style_color_blue;
  }

  .submitform__row--checkbox label {
    border: 6px solid $new_style_color_blue;
  }

  .submitform__row--checkbox input:checked + label {
    @include backgroundimage('new-style/letscreate/tick_blue.svg');
  }

  .submitform__row {
    margin-bottom: 40px;
  }
  .Dropdown-option:hover {
    background-color: transparent;
  }
  .additionial-info p {
    color: $new_style_color_blue;
  }

  .letscreate-nav__wrapper {
    padding: 20px;
  }

  &.no-touchevents .letscreate-nav__item:hover {
    background-color: $new_style_color_blue;
  }
}
