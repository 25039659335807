/* Sort amends : using react-dropdown with different dom structure
------------------------------------------------------------------------------- */
.sort {
  float: left;
  width: 100%;
  margin-bottom: 3%;
  cursor: pointer;
  background-color: #9742b7;
  border-radius: 10px;

  &.active {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
}

@media (max-width: 767px) {
  .Dropdown-root {
    margin-right: 10px;
  }
}

.sort {
  .Dropdown-placeholder {
    display: block;
    float: left;
    width: 100%;
    @include fontsize(28);
    background-color: #ffffff;
    @include backgroundimage('icons/arrow_down.svg');
    background-repeat: no-repeat;
    background-position: 100%;
    background-size: 60px;
    padding-right: 55px;
    color: #9742b7;
    padding: 10px;
    text-decoration: none;
    border-radius: 10px;
  }
  //&--videos .Dropdown-placeholder {
  //  background-color: $color_pink_offset;
  //}
  //&--games .Dropdown-placeholder {
  //  background-color: $color_blue;
  //}
}

html[dir='rtl'] .sort .Dropdown-placeholder {
  background-position: 5% center;
  float: right;
}

.sort:active a,
.sort:focus a {
  //background-color: #EC008C;
}

.sort {
  .Dropdown-menu {
    display: none;
    float: left;
    width: 100%;
    background: #9742b7;
    padding: 2%;
    color: #ffffff;
    border-radius: 10px;
    border: 4px solid #ffffff;
  }
  //&--videos .Dropdown-menu {
  //  background-color: $color_pink_offset;
  //}
  //&--games .Dropdown-menu {
  //  background-color: $color_blue;
  //}
}

.sort .Dropdown-menu .Dropdown-option {
  cursor: pointer;
  @include fontsize(28);
}

.sort.is-open .Dropdown-menu {
  display: block;
}

.sort.is-open .Dropdown-placeholder {
  @include backgroundimage('icons/arrow_up.svg');
}

.sort .Dropdown-option {
  background: none !important;
  padding: 1%;
  color: #ffffff;

  &:hover {
    opacity: 0.8;
  }
}

.sort .Dropdown-option:focus,
.sort .Dropdown-option:hover {
  color: #ffffff;
}
.sort .Dropdown-option.is-selected {
  color: #000000;
}

/* Media queries
------------------------------------------------------------------------------- */

@media all and (min-width: 480px) {
  .sort {
    position: relative;
    float: right;
    width: 320px;
  }

  html[dir='rtl'] .sort {
    float: left;
  }

  .sort .Dropdown-menu {
    position: absolute;
    top: 100%;
    z-index: 40;
    padding: 10px;
  }

  .sort .Dropdown-menu .Dropdown-option {
    @include fontsize(22);
    padding: 5px 0;
  }
}

@media all and (min-width: 768px) {
  .sort {
    float: none;
    position: absolute;
    top: 20px;
    right: 20px;
  }

  html[dir='rtl'] .sort {
    right: auto;
    left: 20px;
  }
}
