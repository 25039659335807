.featured{
	.wrapper{
		padding: $padding*4.5 $padding $padding $padding;
	}
	>.wrapper{
		>.ribbon{
			top:20px;
		}
	}
	.featured_wrapper{
		text-align: center;
		font-size: 0;
		direction: ltr;
	}
	.featured_item{
		position: relative;
		width: 32%;
		margin-left: 1.5%;
		margin-bottom: 1.5%;
		display: inline-block;
		vertical-align: middle;
		&:nth-child(3n+1){
			margin-left: 0;
		}
		.content_item{
			//max-width: 556px;
		}
	}
	&.featured-size-4,
	&.featured-size-2{
		.featured_item{
			width: 49.25%;

			&:nth-child(3n+1){
				margin-left: 1.5%;
			}
			&:nth-child(2n+1){
				margin-left: 0;
			}
		}
	}
}

@media(max-width: 767px){
	.featured{

		.wrapper{
			padding: $padding*2.5 math.div($padding, 2) math.div($padding, 2) math.div($padding, 2);
		}
		>.wrapper{
			>.ribbon{
				top:10px;
			}
		}

		.featured_item{
			width: 100% !important;
			max-width: none !important;
			margin-left: 0 !important;
			margin-bottom: 10px !important;
		}
	}
}
