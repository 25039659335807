$image_path: '../images/';
$image_path_critical: '../images/critical/';
$font_path: '../fonts/';
$font_path_critical: '../fonts/critical/';

$color_facebook: #3b5998;
$color_twitter: #00b0ed;
$color_linkedin: #0065a0;

$color_white: #fff;
$color_blue: #43b8e7;
$color_yellow: #f9eb46;
$color_purple: #853a95;
$color_pink: #e62d91;

$bounce: cubic-bezier(0.47, 2.02, 0.31, -0.36);
$mildbounce: cubic-bezier(0.47, 3.32, 0.31, -0.36);
$superbounce: cubic-bezier(0.47, 5.02, 0.31, -0.36);

$padding: 20px;
$sectionMargin: 25px;
$iconHeight: 60px;

$labelColor: rgba(0, 0, 0, 0.15);

$color_blue: #3791d3;
$color_yellow: #cedc28;
$color_purple: #9742b7;
$color_pink: #cc3366;

$color_pink_offset: #d2006e;
$color_green_offset: #02c764;

$color_letscreate_app_yellow: #f9b928;

// New style vars
$color_new_dark_pink: #d31b80;
$color_new_pink: #fcaee0;
$color_new_blue: #0066ff;
$color_new_light_blue: #2fcdf2;
$color_new_green: #32bf5e;
$color_new_yellow: #f6d638;
