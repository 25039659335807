.flash-message {
  position: relative;
  padding: 100px 20%;
  background-color: rgba(0, 0, 0, 0.15);
  &__title {
    text-align: center;
    @include fontsize(44);
    @include fontb();
    padding: $sectionMargin;
    display: block;
    margin: 0 auto;
  }
  .buttons {
    width: 100%;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .button {
      width: 250px;
      padding-left: 10px;
      padding-right: 10px;

      .button_inner {
        padding: 20px;
      }
    }
  }
}

@media (max-width: 767px) {
  .flash-message {
    padding: 100px 5%;
    &__title {
      @include fontsize(30);
      padding: math.div($sectionMargin, 2);
    }
  }
}
