.like_button {
  display: inline-block;
  vertical-align: top;
  @include noselect();
  @include fontsize(26);
  line-height: 1;
  cursor: pointer;
  background-color: transparent;
  margin-bottom: 0;
  direction: ltr;
  border-radius: 10px;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.2);

  &--hidden {
    display: none;
  }

  .icon_container,
  .count {
    position: relative;
    display: inline-block;
    vertical-align: middle;
  }
  .icon_container {
    background-color: #ffffff;
    width: 44px;
    height: 44px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    &.small {
      width: 40px;
    }
    .icon {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 40px;
      -webkit-transition: -webkit-transform 0.3s;
      transition: transform 0.3s;
      &.small {
        width: 30px;
      }
    }
    .icon_tick {
      @include translatecenter(scale(0));
    }
    .icon_thumbs {
      @include translatecenter(scale(1));
      //@include animation('bounceAnimLoopCenter 3s infinite');
      @include backface();
    }
    //&.small {
    //	width: 44px;
    //	}
  }
  .count {
    color: #ffffff;
    padding: 9px 12px;
    width: 100px;
    text-align: center;

    @media (min-width: 768px) {
      width: auto;
    }
    &.small {
      @include fontsize(20);
      width: 40px;
      padding: 0;
    }
  }
  &.liked {
    cursor: default;
    .icon_container {
      background-color: #9742b7;
      .icon_tick {
        @include translatecenter(scale(1));
      }
      .icon_thumbs {
        //@include animation('none');
        @include translatecenter(scale(0));
      }
    }
  }
}

.no-touchevents {
  .like_button:hover {
    .icon_container {
      background-color: rgba(255, 255, 255, 0.8);
    }

    &.liked:hover {
      .icon_container {
        background-color: lighten(#9742b7, 10%);
      }
    }
  }
}

@media (max-width: 767px) {
  .like_button {
    display: block;
    margin-bottom: 5px;
    width: 100%;
    &.nowrap {
      display: inline-block;
    }
  }
}
