.pre {
  position: relative;
  display: block;
  white-space: pre-wrap;
}

.menu {
  margin-bottom: 0;
}

.menu .flyout {
  display: none;
  float: left;
  width: 100%;
  z-index: 40;
  background: #43b8e7;
  padding: 5% 5% 5% 10%;
  margin-bottom: 5%;
}

.cube_bullet {
  display: none;
  width: 22px;
  position: absolute;
  bottom: 0px;
  left: 8px;
}

.game_element_target {
  background-color: #fff;
  border-radius: 10px;
}

/* Media queries
------------------------------------------------------------------------------- */

@media all and (min-width: 580px) {
  .menu .flyout {
    position: absolute;
    top: 89%;
    padding: 5%;
    margin-top: 0 !important;
    margin-bottom: 0;
  }
}

//.all-videos-list,
.all-games-list {
  direction: ltr;
}

/* #google_ads_iframe_/8494/turner/cartoonnetwork.web.site/mobilehome/roc/mban.320x50_0__container__ { margin: 0 auto; }
*/
#div-gpt-ad-1402421516953-0 > div {
  margin: 0 auto;
}

#ima-ad-container > div:first-child {
  width: 100% !important;
  height: 100% !important;
}

#ima-seek-bar-div {
  width: 100% !important;
}

#adContainer {
  margin: 0 auto;
  display: block;
}

// Old  game iframe styling
//.tablet#gameplay_page_iframe,
//.smartphone#gameplay_page_iframe {
//  position: absolute;
//  top: 0;
//  left: 0;
//}
//.gameiframewrapper {
//  position: absolute;
//  top: 0;
//  left: 0;
//  height: 100%;
//  width: 100%;
//  height: 1px !important; /*ios bug fix*/
//  width: 1px !important; /*ios bug fix*/
//  min-height: 100%; /*ios bug fix*/
//  min-width: 100%; /*ios bug fix*/
//  z-index: 1; /*overflow: auto;*/
//  overflow: auto;
//  -webkit-overflow-scrolling: touch;
//}
//
//.gameiframewrapper > iframe {
//  position: absolute;
//  top: 0;
//  left: 0;
//  height: 100%;
//  width: 100%;
//  height: 1px !important; /*ios bug fix*/
//  width: 1px !important; /*ios bug fix*/
//  min-height: 100%; /*ios bug fix*/
//  min-width: 100%; /*ios bug fix*/
//  z-index: 1;
//}

video {
  z-index: 0 !important;
}
.vjs-control-bar {
  display: none;
}
.kaltura-video {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;
}
.kaltura-video iframe {
  position: absolute;
  top: 0;
  left: 0;
}
