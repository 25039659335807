.caption {
  @include fontsize(24);
  padding: $padding;
  h1 {
    @include fontsize(48);
    @include fontb();
    padding-bottom: math.div($padding, 2);;
  }

  &--center {
    text-align: center;
  }
}

@media (max-width: 767px) {
  .caption {
    @include fontsize(18);
    padding: math.div($padding, 2);;
    h1 {
      @include fontsize(24);
    }
  }
}

.b_yellow,
.b_blue {
  .caption {
    a {
      color: $color_pink;
    }
  }
}

.b_pink,
.b_purple {
  .caption {
    a {
      color: $color_blue;
    }
  }
}

.no-touchevents {
  .caption {
    a:hover {
      text-decoration: underline;
    }
  }
}
