.player {
  position: relative;
  margin-top: 0;
  // >.wrapper{
  //     border-bottom-left-radius: 0px;
  //     border-bottom-right-radius: 0px;
  //     border-bottom: 0;
  // }
  &.the_games.with_related {
    .wrapper {
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      border-bottom: 0;
      color: white;
    }
    ~ .block-list {
      > .wrapper {
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        border-top: 0;
      }
    }
  }
}

.player iframe,
.player object,
.player embed,
.player video {
  margin: 0 auto;
  display: block;
  z-index: 100;
}

.player .bitmovin_player {
  iframe,
  object,
  embed,
  video {
    z-index: 0;
  }
}

.player .video_wrapper {
  max-width: 800px;
  margin: 0 auto;
}

@media all and (min-width: 768px) {
  .player .article {
    float: none;
  }
}
