.photo-buttons{
	position: relative;
	width: 100%;
	text-align: center;
	margin: 10px auto;
}
.photo-button{
	position: relative;
	width:50px;
	height:50px;
	margin-right: 4px;
	display:inline-block;
	vertical-align: top;
	background-size: 80% auto;
	background-position: center;
	background-repeat: no-repeat;
	background-color: $color_blue;
	cursor: pointer;
	&:last-child{
		margin-right: 0;
	}
}

.no-touchevents{
	.photo-button:hover{
		background-color: lighten($color_blue, 10%);
	}
}

@media(max-width: 767px){

	.photo-button{
		width:42px;
		height:42px;
	}

}
