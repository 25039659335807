$edgeHang: 15px;
$ribbonPadding: 8px;
.ribbon {
  position: absolute;
  top: 10px;
  left: -$edgeHang;
  color: #ffffff;
  background-color: #cc3366;
  padding: $ribbonPadding;
  padding-left: $ribbonPadding + $edgeHang;
  @include fontsize(22);
  line-height: 1;
  @include fontbl();
  z-index: 1;
  &:before {
    content: '';
    position: absolute;
    display: block;
    border-style: solid;
    top: 100%;
    left: 0;
    border-width: $edgeHang 0 0 $edgeHang;
    border-color: darken(#cc3366, 20%) transparent transparent transparent;
  }
  &:after {
    content: '';
    position: absolute;
    display: block;
    width: 40px;
    height: 100%;
    //border-style: solid;
    background-color: #cc3366;
    top: 0;
    right: -15px;
    z-index: -1;
    border-radius: 10px;
    transform: skewX(-20deg);
    //border-width: 38px 35px 0 0;
    //border-color: $color_pink transparent transparent transparent;
  }
  &.ribbon-purple {
    background-color: #9742b7;
    &:before {
      border-color: darken(#9742b7, 20%) transparent transparent transparent;
    }
    &:after {
      background-color: #9742b7;
    }
  }
  &.ribbon-yellow {
    background-color: #cc3366;

    &:before {
      border-color: darken(#cc3366, 20%) transparent transparent transparent;
    }
    &:after {
      background-color: #cc3366;
    }
  }
  &.ribbon-blue {
    background-color: #cc3366;
    &:before {
      border-color: darken(#cc3366, 20%) transparent transparent transparent;
    }
    &:after {
      background-color: #cc3366;
    }
  }

  &.ribbon-large {
    @include fontsize(32);
    line-height: 1;
    &:after {
      border-width: 48px 45px 0 0;
    }
  }
}

[dir='rtl'] {
  .ribbon {
    left: auto;
    right: -$edgeHang;
    @include transform(scale(-1, 1));
    h2,
    span {
      display: block;
      @include transform(scale(-1, 1));
    }
  }
}

@media (max-width: 767px) {
  $edgeHang: 8px;
  $ribbonPadding: 5px;
  .ribbon {
    left: -$edgeHang;
    padding: $ribbonPadding;
    padding-left: $ribbonPadding + $edgeHang;
    @include fontsize(13);
    line-height: 1;
    &:before {
      border-width: $edgeHang 0 0 $edgeHang;
    }
    &:after {
      border-width: 23px 23px 0 0;
    }
    &.ribbon-large {
      @include fontsize(22);
      line-height: 1;
      &:after {
        border-width: 32px 30px 0 0;
      }
    }
  }
  [dir='rtl'] {
    .ribbon {
      left: auto;
      right: -$edgeHang;
    }
  }
}
