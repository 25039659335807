// 'NEW STYLE' stlying
@mixin background-blue {
  background-color: $color_new_blue;
  @include backgroundimage('new-style/bg-texture.png');
  background-repeat: repeat;
  background-size: 100%;
  position: relative;
  padding-bottom: 40px;

  &:after {
    content: '';
    width: 100%;
    height: 37px;
    position: absolute;
    top: -34px;
    left: 0;
    @include backgroundimage('new-style/topper-blue.png');
    background-repeat: repeat-x;
    z-index: 10;
  }

  &:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    @include backgroundimage('new-style/bg-clouds-texture.png');
    background-size: 100%;
  }
}

// CONTENT BLOCK COLOUR: BLUE
@mixin background-light-blue {
  background-color: $color_new_light-blue;
  @include backgroundimage('new-style/bg-texture.png');
  background-repeat: repeat;
  background-size: 100%;
  position: relative;
  padding-bottom: 40px;

  &:after {
    content: '';
    width: 100%;
    height: 37px;
    position: absolute;
    top: -34px;
    left: 0;
    @include backgroundimage('new-style/topper-light-blue.png');
    background-repeat: repeat-x;
    z-index: 10;
  }
}

// CONTENT BLOCK COLOUR: PURPLE
@mixin background-green {
  background-color: $color_new_green;
  @include backgroundimage('new-style/bg-texture.png');
  background-repeat: repeat;
  background-size: 100%;
  position: relative;
  padding-bottom: 40px;

  &:after {
    content: '';
    width: 100%;
    height: 37px;
    position: absolute;
    top: -34px;
    left: 0;
    @include backgroundimage('new-style/topper-green.png');
    background-repeat: repeat-x;
    z-index: 10;
  }

  &:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    @include backgroundimage('new-style/bg-stars-texture.png');
    background-position: 0px 3px;
    background-repeat: repeat;
    background-size: 100%;
  }
}

// CONTENT BLOCK COLOUR: YELLOW
@mixin background-yellow {
  background-color: $color_new_yellow;
  @include backgroundimage('new-style/bg-texture.png');
  background-repeat: repeat;
  background-size: 100%;
  position: relative;
  padding-bottom: 40px;

  &:after {
    content: '';
    width: 100%;
    height: 37px;
    position: absolute;
    top: -34px;
    left: 0;
    @include backgroundimage('new-style/topper-yellow.png');
    background-repeat: repeat-x;
    z-index: 10;
  }

  &:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    @include backgroundimage('new-style/bg-shapes-texture.png');
    background-position: 0px 3px;
    background-repeat: repeat;
    background-size: 100%;
  }
}

// CONTENT BLOCK COLOUR: PINK
@mixin background-pink {
  background-color: $color_new_pink;
  @include backgroundimage('new-style/bg-texture.png');
  background-repeat: repeat;
  background-size: 100%;
  position: relative;
  padding-bottom: 40px;

  &:after {
    content: '';
    width: 100%;
    height: 37px;
    position: absolute;
    top: -34px;
    left: 0;
    @include backgroundimage('new-style/topper-pink.png');
    background-repeat: repeat-x;
    z-index: 10;
  }

  &:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    @include backgroundimage('new-style/bg-shapes-texture.png');
    background-position: 0px 3px;
    background-repeat: repeat;
    background-size: 100%;
  }
}

@mixin nav-background-blue {
  background-color: $color_new_blue;
  padding-top: 5px;
  padding-bottom: 40px;
  @include backgroundimage('new-style/bg-texture.png');
  background-repeat: repeat;
  background-size: 100%;

  &:before {
    content: '';
    height: 5px;
    width: 100%;
    background: rgb(56, 179, 214);
    background: -moz-linear-gradient(90deg, rgba(56, 179, 214, 1) 0%, rgba(222, 192, 44, 1) 100%);
    background: -webkit-linear-gradient(90deg, rgba(56, 179, 214, 1) 0%, rgba(222, 192, 44, 1) 100%);
    background: linear-gradient(90deg, rgba(56, 179, 214, 1) 0%, rgba(222, 192, 44, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#38b3d6",endColorstr="#dec02c",GradientType=1);
    top: 0;
    left: 0;
    position: absolute;
  }

  &:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 10px;
    left: 0;
    @include backgroundimage('new-style/bg-stars-texture.png');
    background-repeat: repeat;
    background-size: 100%;
    z-index: 2;
  }
}

html.new-style {
  // buttons
  .buttons {
    .button .button_inner {
      background-color: #fff;
      color: #000;
    }
  }

  // body.webp {
  //   header .logo {
  //     @include backgroundimage('new-style/cartoonito-logo-repeat.webp');
  //   }
  //
  //   .featured_header {
  //     .character {
  //       @include backgroundimage('new-style/character-repeat.webp');
  //     }
  //   }
  //
  //   // gameplayer page
  //   .gameplay_page .player {
  //     .character {
  //       @include backgroundimage('new-style/character-repeat.webp');
  //     }
  //   }
  // }

  #showsContent {
    @media only screen and (min-width: 1020px) {
      margin-top: 25px;
    }
  }

  body {
    background-color: $color_new_yellow;
  }

  // Footer Links - privacy policy page etc
  .body_wrapper.footer_links {
    .footer_link {
      background-color: #fff;
      border-radius: 25px;
      position: relative;
      z-index: 6;
      margin-bottom: 20px;
    }

    .buttons--footer-view {
      padding-top: 40px;
    }
  }

  // Shows carousel
  .shows_carousel {
    .arrow {
      width: 50px;

      @media only screen and (min-width: 768px) {
        opacity: 0;
        width: 100px;
      }

      background-color: transparent;
      &.swiper-button-disabled {
        opacity: 0;
      }
      &.arrow-left:before {
        @include backgroundimage('new-style/icons/arrow_left.svg');
      }
      &.arrow-right:before {
        @include backgroundimage('new-style/icons/arrow_right.svg');
      }
    }
  }

  &.no-touchevents .shows_carousel:hover .arrow {
    opacity: 1;
    transform: translateX(20px);
    @media only screen and (min-width: 768px) {
      transform: translateX(60px);
    }

    &.arrow-left {
      transform: translateX(-20px);
      @media only screen and (min-width: 768px) {
        transform: translateX(-60px);
      }
    }
  }

  &.touchevents .shows_carousel .arrow {
    display: block !important;
    opacity: 1;
    transform: translateX(45px);

    &.arrow-left {
      transform: translateX(-45px);
    }

    &.swiper-button-disabled {
      opacity: 0;
    }
  }

  .body_wrapper {
    overflow: visible;
    width: 100% !important;
  }

  #ad_oop_float_01,
  #ad_reskin_skin_01 {
    height: 0;
  }

  .b_pink,
  .b_purple,
  .b_yellow,
  .b_blue {
    background-color: transparent;
    border: none;
  }

  // headings
  h1,
  h2,
  h3,
  h4 {
    color: #000 !important;
    font-weight: 700;
  }
  h2 {
    @include fontsize(30);
  }

  // header
  header {
    background-color: $color_new_dark_pink;
    @include backgroundimage('new-style/bg-header-texture.png');
    background-size: cover;
    background-repeat: repeat;
    position: relative;

    @media only screen and (min-width: 1024px) {
      overflow: hidden;
    }

    @media only screen and (min-width: 768px) {
      &:before {
        content: '';
        width: 168px;
        height: 165px;
        @include backgroundimage('new-style/header-character-left.png');
        position: absolute;
        top: 50px;
        left: 20px;
      }
    }

    > .wrapper {
      background-color: transparent;
    }
  }

  .mobile_nav {
    background-color: $color_new_dark_pink;
    @include backgroundimage('new-style/bg-texture.png');
    background-repeat: repeat;
    background-size: 100%;
  }

  .mobile_nav .nav_item .nav_item_icon {
    background-size: 45px 85px;
  }

  // .mobile_nav .nav_item.active .nav_item_icon.nav_item_icon--svg-modifier {
  //   background-color: white;
  //   background-position: bottom;
  //   background-size: px 78px !important;
  //   border-radius: 10px;
  // }

  // Show carousel
  .shows_carousel .swiper-container a .shows_carousel_title {
    background-color: #fff;
    color: #000;
  }

  nav {
    z-index: 5;
    > .wrapper {
      background-color: transparent;

      margin: 0 auto;
      max-width: 1228px;
      padding: 20px;
      position: relative;
      width: 96%;

      @media only screen and (min-width: 1024px) {
        &:before {
          @include backgroundimage('new-style/header-character-right.png');

          background-repeat: no-repeat;
          background-position: bottom right;
          bottom: 0;
          content: '';
          height: 290px;
          position: absolute;
          right: -265px;
          width: 265px;
        }
      }
    }
  }

  nav .nav_item {
    padding: 5px 10px;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      @include backgroundimage('new-style/pink-opacity-bg-nav.png');
      background-size: 100% 100%;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;

      transition: opacity 0.1s;
      transition-delay: 0.3s;
    }
    > * {
      position: relative;
      z-index: 1;
    }
  }

  // nav .nav_item .nav_item_icon {
  //   width: 35px;
  //   height: 35px;
  //   background-position: center;
  // }

  // nav .nav_item.active .nav_item_icon {
  //   background-position: center;
  // }

  nav .nav_item.active {
    color: #000;
    background-color: #fff;

    &:before {
      opacity: 0;
      transition-delay: 0s;
    }
  }
  &.no-touchevents nav .nav_item:hover {
    background-color: #fff;
    color: #000;
    &:before {
      opacity: 0;
      transition-delay: 0s;
    }

    .nav_item_icon {
      background-position: center 100%;
    }
  }
  // .mobile_nav .nav_item .nav_item_icon {
  //   width: 35px;
  // }

  .shows_carousel_marker {
    padding-bottom: 0;
  }

  .shows_carousel {
    @include nav-background-blue;

    &.sticky {
      position: fixed;
      padding-bottom: 0;
      box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.3);
    }

    .shows_carousel_wrapper {
      background-color: transparent;
      margin: 0 auto;
      max-width: 1228px;
      padding: 20px;
      position: relative;
      width: 96%;
      z-index: 5;
    }
  }

  .show-specific-cta {
    padding-top: 40px;
    @include background-green;

    > .wrapper {
      background-color: transparent;
      border: none;
      position: relative;
    }

    .show-cta {
      margin-bottom: 40px;
    }
  }

  .content_item_inner {
    margin-bottom: 15px;
    box-shadow: 0px 5px 15px rgb(0 0 0 / 20%);
    border-radius: 10px;
  }

  header .logo {
    @include backgroundimage('new-style/pink-opacity-bg.png');
    background-size: 100%;

    position: relative;
    animation: none;

    height: 70px;
    width: 320px;

    @media only screen and (min-width: 768px) {
      height: 80px;
      width: 400px;
    }

    .logo-channel {
      @include backgroundimage('new-style/logo_channel.png');
      right: 0;
      top: 0;
      width: 36px;
      height: 37px;
      position: absolute;
      background-size: 100%;

      @media only screen and (min-width: 768px) {
        right: -30px;
        top: -20px;
        width: 48px;
        height: 49px;
      }
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      z-index: 1;
      left: 0;
      background-size: 100%;

      @include backgroundimage('new-style/cartoonito-logo-repeat.gif');

      z-index: 2;

      background-position: 50% -45px;
      width: 100%;
      height: 100%;

      @media only screen and (min-width: 768px) {
        background-position: 50% -90px;
        background-size: 125%;
      }
    }

    &::after {
      content: '';
      @include backgroundimage('new-style/character-static.png');
      background-size: 100%;

      left: 50%;
      transform: translateX(-50%);
      position: absolute;

      background-position: 50% -45px;
      height: 70px;
      width: 320px;

      @media only screen and (min-width: 768px) {
        background-position: 50% -90px;

        height: 100px;
        width: 500px;
      }
    }
  }

  header > .wrapper .logo-and-menu-btn-wrapper {
    margin-bottom: 10px;
  }

  .featured_header,
  .gameplay_page .player,
  .editorial_area {
    @include background-yellow;

    > .wrapper {
      background-color: transparent;
      border: none;
      position: relative;
      // z-index: 6;
    }
  }

  .editorial_area {
    margin-top: 0;
  }

  .featured_header {
    padding-bottom: 180px;

    .character {
      @include backgroundimage('new-style/character-repeat.gif');
      background-repeat: no-repeat;
      background-size: auto 150%;
      background-position: 50% top;
      height: 290px;
      position: absolute;
      width: 40%;
      left: 50%;
      transform: translateX(-50%);

      bottom: 10px;

      @media only screen and (min-width: 1024px) {
        bottom: -15px;
      }
    }
  }

  // gameplayer page
  body.gameplay_page .gallery__content-blocks .block-list {
    margin-bottom: 0;
    padding-bottom: 20px;
  }
  .gameplay_page .player {
    .game_element_target {
      border-radius: 0;
    }
    .caption {
      background-color: #fff;
      color: #000;
      border-bottom-right-radius: 15px;
      border-bottom-left-radius: 15px;
    }

    .character {
      @include backgroundimage('new-style/character-repeat.gif');
      background-repeat: no-repeat;
      background-size: auto 150%;
      background-position: 50% top;
      top: 50%;
      height: 290px;
      position: absolute;
      width: 40%;
      right: -13%;
      transform: translateY(-50%) rotate(-90deg);
      display: none;

      @media only screen and (min-width: 768px) {
        display: block;
      }

      @media only screen and (min-width: 1601px) {
        right: -15%;
      }

      @media only screen and (min-width: 2880px) {
        right: -16%;
      }
    }
  }

  .gameplay_page #related-items {
    @media only screen and (min-width: 768px) {
      @include background-light-blue;
    }
  }

  @media only screen and (orientation: landscape) {
    .game-container #related-games .content_item_inner {
      overflow: hidden;
      margin-bottom: 0;

      img {
        border-radius: 0;
      }
    }
  }

  // Video page
  .featured_header .featured_header_item:first-child .label {
    padding: 10px 15px;
  }

  .videos {
    .featured_header .featured_header_item .video_wrapper {
      border-radius: 0;
    }

    // playlist
    .video-playlist__toggle input:checked + .slider {
      background-color: $color_new_dark_pink;
    }
    .video-playlist__video-list {
      background-color: $color_new_dark_pink;
      border-radius: 10px;
    }

    .video-playlist__items {
      padding: 10px;
    }

    .video-playlist__title {
      color: #000;
      font-weight: 700;
      @include fontb;
      @include fontsize(25);
    }

    @media only screen and (min-width: 768px) {
      .scroll-button {
        background: $color_new_dark_pink;
      }
      .scroll-button.up:after {
        background: -webkit-gradient(linear, left bottom, left top, from(rgba(40, 39, 37, 0)), to($color_new_dark_pink));
        background: -o-linear-gradient(bottom, rgba(40, 39, 37, 0) 0, $color_new_dark_pink 100%);
        background: linear-gradient(0deg, rgba(40, 39, 37, 0), $color_new_dark_pink);
        -webkit-box-shadow: inset 12px 0 4px -4px $color_new_dark_pink, inset -12px 0 4px -4px $color_new_dark_pink, inset 0 6px 4px -4px $color_new_dark_pink;
        box-shadow: inset 12px 0 4px -4px $color_new_dark_pink, inset -12px 0 4px -4px $color_new_dark_pink, inset 0 6px 4px -4px $color_new_dark_pink;
      }
      .scroll-button.bottom:after {
        background: -webkit-gradient(linear, left top, left bottom, from(rgba(40, 39, 37, 0)), to($color_new_dark_pink));
        background: -o-linear-gradient(top, rgba(40, 39, 37, 0) 0, $color_new_dark_pink 100%);
        background: linear-gradient(180deg, rgba(40, 39, 37, 0), $color_new_dark_pink);
        -webkit-box-shadow: inset 12px 0 4px -4px $color_new_dark_pink, inset -12px 0 4px -4px $color_new_dark_pink, inset 0 -6px 4px -4px $color_new_dark_pink;
        box-shadow: inset 12px 0 4px -4px $color_new_dark_pink, inset -12px 0 4px -4px $color_new_dark_pink, inset 0 -6px 4px -4px $color_new_dark_pink;
      }
    }
  }

  .shows .emojis,
  .videoplayer .emojis,
  .videos .emojis {
    margin-left: unset;
    .emojis__container .emoji {
      flex-basis: calc(25% - 30px);
    }
  }

  .shows .emojis.active .emojis__container,
  .videoplayer .emojis.active .emojis__container,
  .videos .emojis.active .emojis__container {
    height: 50px;
  }

  @media only screen and (max-width: 768px) {
    .shows .emojis .emojis__container .emoji:first-child,
    .videoplayer .emojis .emojis__container .emoji:first-child,
    .videos .emojis .emojis__container .emoji:first-child {
      right: 20px;
      top: -120px;
    }

    .shows .emojis .emojis__container .emoji:nth-child(2),
    .videoplayer .emojis .emojis__container .emoji:nth-child(2),
    .videos .emojis .emojis__container .emoji:nth-child(2) {
      right: 150px;
      top: -80px;
    }

    .shows .emojis .emojis__container .emoji:nth-child(3),
    .videoplayer .emojis .emojis__container .emoji:nth-child(3),
    .videos .emojis .emojis__container .emoji:nth-child(3) {
      right: 160px;
      top: -80px;
    }

    .shows .emojis .emojis__container .emoji:nth-child(4),
    .videoplayer .emojis .emojis__container .emoji:nth-child(4),
    .videos .emojis .emojis__container .emoji:nth-child(4) {
      right: 150px;
      top: 15px;
    }

    .shows .emojis .emojis__container .emoji:nth-child(5),
    .videoplayer .emojis .emojis__container .emoji:nth-child(5),
    .videos .emojis .emojis__container .emoji:nth-child(5) {
      right: 40px;
      top: -25px;
    }
  }

  // end video page

  .content_item .content_item_label,
  .content_item .label {
    background-color: #fff !important;
    color: #000;
  }

  .content_item__icon {
    height: 40px;
  }

  .content_item--web_game .content_item__icon {
    height: 30px;
  }

  // emojis
  .emojis .emoji .count {
    color: #000;
  }

  .view_counter .count {
    color: #000;
  }

  // hero section
  .hero_section {
    h1,
    h2,
    h3 {
      color: #fff !important;
    }
  }

  .buttons--footer-view .button .button_icon {
    background-position: center bottom;
    background-size: 80%;
    height: 45px;
    top: 45%;
  }

  // show more button
  .more_button {
    width: 350px;
    color: #000;
    .icon:after,
    .icon:before {
      background-color: $color_new_dark_pink;
      height: 7px;
      width: 80%;
    }

    &:hover {
      .icon:after,
      .icon:before {
        background-color: #000;
      }
    }
  }
  &.no-touchevents .more_button:hover {
    background-color: $color_new_dark_pink;
    color: #000;
  }

  .body_wrapper:not(.games) .buttons--footer-view,
  .body_wrapper.gameplay_page .buttons--footer-view {
    margin-top: 0;
    background-color: $color_new_blue;
    @include backgroundimage('new-style/bg-texture.png');
    background-repeat: repeat;
    background-size: 100%;
    position: relative;
    padding-bottom: 40px;
  }

  .body_wrapper:not(.games) .gallery__content-blocks,
  .body_wrapper.gameplay_page .gallery__content-blocks {
    margin-top: -25px;

    // show more button
    .show-more-items {
      width: 350px;
      color: #000;
      .icon:after,
      .icon:before {
        background-color: $color_new_dark_pink;
        height: 7px;
        width: 80%;
      }

      &:hover {
        .icon:after,
        .icon:before {
          background-color: #000;
        }
      }
    }
    .no-touchevents .b_purple .more_button:hover {
      background-color: $color_new_dark_pink;
    }

    .block-list,
    .featured {
      margin-top: 0;

      .wrapper {
        position: relative;
        border-top: none !important;
      }
    }

    .purple_block {
      @include background-green;
    }
    .yellow_block {
      @include background-yellow;
    }
    .blue_block {
      @include background-light-blue;
    }
    .pink_block {
      @include background-pink;
    }

    div.filter-section {
      section.block-list {
        @include background-light-blue;
        padding-bottom: 40px;

        &:not(.margin_top) {
          &::after {
            display: block;
          }
        }
      }
    }

    .featured {
      @include background-blue;
    }

    .ribbon {
      background-color: transparent;
      position: static;
      padding: 10px 5px;
      &::before,
      &::after {
        display: none;
      }
    }
  }

  .featured {
    margin-top: 0;
    @include background-blue;

    .wrapper {
      position: relative;
    }

    .ribbon {
      background-color: transparent;
      position: static;
      padding: 10px 5px;
      &::before,
      &::after {
        display: none;
      }
    }
  }

  // sort dropdown
  #letscreate_app .Dropdown-control {
    background-color: transparent !important;
    border: none !important;
    border-radius: 0 !important;
    color: #000 !important;

    .Dropdown-arrow {
      display: none;
    }
  }
  #letscreate_app .Dropdown-menu {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin-top: 0;
  }
  body.games .Dropdown-root {
    min-width: 100%;
    @media only screen and (min-width: 768px) {
      min-width: 210px;
    }

    .Dropdown-menu {
      margin-top: 0;
      background-color: #fff;
      color: #000;
      padding: 0;
      border: none;
      border-radius: 20px;
      border-top-right-radius: 0;
      border-top-left-radius: 0;
    }
  }

  .Dropdown-root {
    border-radius: 20px;
    width: auto;

    .Dropdown-control {
      background-color: transparent;
      border: none;
      border-radius: 0;
      color: #000;
      transition: none;
      padding: 0;
      overflow: visible;
    }

    .Dropdown-placeholder {
      border-radius: 20px;
      color: #000;
      @include backgroundimage('new-style/icons/arrow_down.svg');
      background-color: #fff;
      background-repeat: no-repeat;
      background-position: 100%;
      background-size: 60px;
      display: block;
      float: left;
      font-size: 28px;
      font-size: 2.8rem;
      line-height: 1.1;
      padding: 10px;
      text-decoration: none;
      width: 100%;
      padding-right: 55px;
    }

    &.is-open {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;

      .Dropdown-placeholder {
        color: #000;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        @include backgroundimage('new-style/icons/arrow_up.svg');
      }
    }

    .Dropdown-menu {
      margin-top: 0;
      background-color: #fff;
      color: #000;
      padding: 0;
      border: none;
      border-radius: 20px;
      border-top-right-radius: 0;
      border-top-left-radius: 0;
    }

    .Dropdown-option {
      color: #000;
      padding: 15px;
      @include fontsize(28);
      &.is-selected {
        background-color: rgba(211, 27, 128, 0.1) !important;
        border-radius: 20px;
      }
    }
  }

  .body_wrapper.games:not(.gameplay_page) {
    margin-top: -20px;
    @include background-yellow;

    #gamesContent {
      position: relative;

      h2,
      h3 {
        color: #000;
      }
    }

    .body_wrapper {
      padding: 0;
    }

    .featured {
      background: none;
      &:before,
      &:after {
        display: none;
      }
      padding: 0;
      h2 {
        @include fontsize(30);
      }
    }

    .ribbon {
      color: #000;
      background-color: transparent;
      position: static;
      padding: 10px 5px;
      &::before,
      &::after {
        display: none;
      }
    }
  }

  .hero_section {
    @include background-light-blue;

    .wrapper {
      border-radius: 15px;
      overflow: hidden;

      .caption {
        background-color: $color_new_blue;
      }
    }
  }

  .ribbon {
    background-color: $color_new_dark_pink;
    &::before {
      border-color: #660c46 transparent transparent;
    }
    &::after {
      background-color: $color_new_dark_pink;
    }
  }

  footer {
    margin-top: 0;

    &:before {
      content: '';
      height: 8px;
      width: 100%;
      background: rgb(56, 179, 214);
      background: -moz-linear-gradient(90deg, rgba(56, 179, 214, 1) 0%, rgba(222, 192, 44, 1) 100%);
      background: -webkit-linear-gradient(90deg, rgba(56, 179, 214, 1) 0%, rgba(222, 192, 44, 1) 100%);
      background: linear-gradient(90deg, rgba(56, 179, 214, 1) 0%, rgba(222, 192, 44, 1) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#38b3d6",endColorstr="#dec02c",GradientType=1);
      top: 0;
      left: 0;
      position: absolute;
    }

    .wrapper {
      border-radius: 0;
      width: 100%;
    }
  }

  // Mobile Game container
  // emojis
  .mobile-emojis #emoji-container .emoji .count {
    background-color: transparent;
  }

  // container
  .game-container__related-games .related-games-icon {
    @include backgroundimage('new-style/icons/mobile-games.svg');
    background-size: 90%;
  }

  .game-container.active .game-container__related-games .related-games-icon {
    @include backgroundimage('icons/mobile-close.svg');
    background-size: 50%;
  }

  .game-container__home-item,
  .game-container__related-games .related-games-icon,
  .game-container {
    background-color: $color_new_blue;
  }

  // letscreate_app
  #letscreate_app {
    @include background-yellow;
    margin-top: -20px;
    .wrapper {
      position: relative;
    }

    .wrapper.b_purple {
      background-color: transparent;
    }
  }

  #letscreate_app .entryitem__itemdetails {
    background-color: #fff;
    color: #000;

    .lc_like_button--grey,
    .watched__button--grey {
      color: #000;
      background-color: rgba(0, 0, 0, 0.07);
    }
    .lc_like_button .count,
    .watched__button .count {
      color: #000;
    }
  }

  .letscreate-header {
    @include background-pink;
    .wrapper {
      position: relative;
    }
  }

  .letscreate-nav {
    @include background-light-blue;
    padding-top: 20px;
    .wrapper {
      position: relative;
    }

    .letscreate-nav__mobilemenubutton {
      background-color: transparent;
    }

    .letscreate-nav__icon--mobile {
      @include backgroundimage('new-style/icons/arrow_down.svg');
      background-color: #fff;
      border-radius: 10px;
    }

    .letscreate-nav__item {
      border-radius: 10px;
    }

    .letscreate-nav__text {
      color: #000;
    }

    .letscreate-nav__item:last-child {
      margin-right: 0px;

      @media only screen and (min-width: 768px) {
        margin-right: 20px;
      }
    }
  }

  .entry__footer,
  .entry__header,
  .modal__button {
    background-color: #dec02c !important;
  }

  &.no-touchevents .letscreate-nav__item:hover {
    background-color: $color_new_dark_pink;
  }

  .featured-letscreate-feed {
    @include background-pink;
    .wrapper {
      position: relative;
    }
    margin-top: -20px;
  }

  .mostliked-letscreate-feed {
    @include background-blue;
    .wrapper {
      position: relative;
    }
    margin-top: -20px;
  }

  .winners-letscreate-feed {
    @include background-green;
    .wrapper {
      position: relative;
    }
    margin-top: -20px;
  }

  .letscreate-feed {
    .wrapper {
      position: relative;
    }

    .b_white {
      background-color: transparent;
    }

    .feed__itemdetails .lc_like_button--grey,
    .feed__itemdetails .watched__button--grey {
      color: #000;
      background-color: rgba(0, 0, 0, 0.07);
    }
    .feed__itemdetails .lc_like_button .count,
    .feed__itemdetails .watched__button .count {
      color: #000;
    }
  }

  // Placing Character in backgrounds
  // on different pages
  .body_wrapper.videos {
    .gallery__content-blocks {
      > div:nth-child(1) {
        position: relative;
        @media only screen and (min-width: 1024px) {
          .block-list {
            position: relative;
            z-index: 6;
          }
          .character-image-holder {
            position: absolute;
            width: 100%;
            height: 100%;
            &:before {
              content: '';
              width: 250px;
              height: 240px;
              left: 0;
              background-size: 100% auto;
              @include backgroundimage('new-style/body-character-1.png');
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              z-index: 4;
            }
          }
        }
      }

      > div:nth-child(2) {
        position: relative;
        @media only screen and (min-width: 1024px) {
          .block-list {
            position: relative;
            z-index: 6;
          }
          .character-image-holder {
            position: absolute;
            width: 100%;
            height: 100%;
            &:before {
              content: '';
              width: 250px;
              height: 240px;
              right: 0;
              background-size: 100% auto;
              @include backgroundimage('new-style/body-character-3.png');
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              z-index: 4;
            }
          }
        }
      }
    }
  }

  .body_wrapper.home {
    .gallery__content-blocks {
      > div .block_section:nth-child(2) {
        position: relative;
        @media only screen and (min-width: 1024px) {
          .block-list {
            position: relative;
            z-index: 6;
          }
          .character-image-holder {
            position: absolute;
            width: 100%;
            height: 100%;

            &::before {
              @include backgroundimage('new-style/body-character-fixed-bottom.png');
              background-size: 100% auto;
              top: 50%;
              transform: translateY(-50%);
              content: '';
              height: 190px;
              position: absolute;
              right: 0;
              width: 190px;
              z-index: 4;
            }
          }
        }
      }

      > div .block_section:nth-child(5):not(.yellow) {
        @media only screen and (min-width: 1024px) {
          .block-list {
            position: relative;
            z-index: 6;
          }
          .character-image-holder {
            position: absolute;
            width: 100%;
            height: 100%;

            &:before {
              content: '';
              width: 250px;
              height: 240px;
              left: 0;
              background-size: 100% auto;
              @include backgroundimage('new-style/body-character-1.png');
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              z-index: 4;
            }
          }
        }
      }
    }
  }

  .body_wrapper.games {
    .gameCategorys {
      position: relative;
      z-index: 5;
    }
    @media only screen and (min-width: 1024px) {
      #gamesContent:before {
        content: '';
        width: 150px;
        height: 160px;
        left: 75px;
        background-size: 100% auto;
        @include backgroundimage('new-style/body-character-2.png');
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 4;
      }

      .gallery__content-blocks {
        position: relative;
        z-index: 6;
      }
    }
  }

  .body_wrapper.gameplay_page {
    overflow: hidden;
    .gallery__content-blocks {
      > div .block_section:nth-child(1) {
        position: relative;
        @media only screen and (min-width: 1024px) {
          .block-list {
            position: relative;
            z-index: 6;
          }
          .character-image-holder {
            position: absolute;
            width: 100%;
            height: 100%;

            &:before {
              @include backgroundimage('new-style/body-character-fixed-bottom.png');
              background-size: 100% auto;
              bottom: 0;
              content: '';
              height: 190px;
              position: absolute;
              right: -20%;
              width: 190px;
              z-index: 4;
            }
          }
        }
      }
    }
  }

  .body_wrapper.apps {
    .gallery__content-blocks {
      > div .block_section:nth-child(1) {
        position: relative;
        @media only screen and (min-width: 1024px) {
          .block-list {
            position: relative;
            z-index: 6;
          }
          .character-image-holder {
            position: absolute;
            width: 100%;
            height: 100%;

            &:before {
              @include backgroundimage('new-style/body-character-1.png');
              background-size: 100% auto;
              bottom: 0;
              content: '';
              height: 190px;
              position: absolute;
              left: -20%;
              width: 190px;
              z-index: 4;
            }
          }
        }
      }
    }
  }

  // Ribbon
  .ribbon--small {
    background-color: #fbc105 !important;
    padding: 10px 25px !important;
    position: absolute !important;
    left: 0px !important;
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
    color: #fff !important;

    &:after,
    &:before {
      display: none;
    }
  }
}

html[dir='rtl'] {
  // Ribbon
  .ribbon--small {
    left: auto !important;
    right: 0 !important;
  }
}
