.more_button {
  position: relative;
  display: block;
  width: 320px;
  background-color: #ffffff;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
  color: #9742b7;
  padding: 20px;
  text-decoration: none;
  @include fontsize(34);
  @include fontb();
  margin: 0 auto;
  cursor: pointer;
  border-radius: 10px;
}

.more_button span {
  display: block;
  margin: 0;
}

.more_button .icon {
  position: absolute;
  top: 50%;
  right: 20px;
  @include translatecentery();
  @include fontsize(50);
  font-family: Arial, sans-serif;
  -webkit-transition: -webkit-transform 0.3s $bounce;
  transition: transform 0.3s $bounce;
}

html[dir='rtl'] {
  .more_button span {
    //width : 80%;
    margin-right: 20%;
  }

  .more_button .icon {
    margin-right: 0%;
  }
}

html[dir='rtl'] .more_button .icon {
  float: left;
}
.no-touchevents {
  .more_button:hover {
    background-color: #9742b7;
    color: #ffffff;
    .icon {
      @include translatecentery(rotate(-20deg));
    }
  }
}
.more_button:active,
.more_button:focus {
  background-color: $color_pink;
}
.no-touchevents .b_purple .more_button:hover {
  background-color: #ac68c5;
}
