footer {
  position: relative;
  width: 100%;
  display: block;
  color: #ffffff;
  @include fontsize(24);
  margin-top: $sectionMargin;

  margin-bottom: 2%;

  @media (min-width: 768px) {
    background-color: #000;
    margin-bottom: 0;
  }

  .wrapper {
    padding-left: 100px;
    padding-right: 100px;
    padding-top: 40px;

    width: 96%;
    border-radius: 20px;

    @media (max-width: 768px) {
      background-color: #000;
    }
  }
  .footer_navigation,
  .copyright {
    position: relative;
    display: inline-block;
    vertical-align: middle;
  }
  a {
    color: #9742b7;
  }

  .footer_navigation {
    padding-right: $padding * 2;
    width: 30%;
    @include fontb();
    @include fontsize(18);
    border-right: 1px solid #ffffff;
    a {
      margin-bottom: $padding;
      display: block;
      &:before {
        content: '';
        display: inline-block;
        width: 4px;
        height: 4px;
        margin-top: -5px;
        margin-right: 5px;
        vertical-align: middle;
        border-radius: 50%;
        background-color: #ffffff;
        //@include backgroundimage('icons/arrow_right.svg');
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .copyright {
    @include fontreg();
    @include fontsize(18);
    text-transform: none;
    padding-left: $padding * 2;
    width: 69%;
  }

  .footer-logos {
    margin-bottom: 20px;
    display: flex;

    img {
      float: left;
      width: 30.3333%;
      margin-right: 3%;
      max-height: 50px;
    }
  }
}

.no-touchevents {
  footer {
    a:hover {
      color: #ffffff;
    }
  }
}

[dir='rtl'] {
  footer {
    .footer_navigation {
      padding-right: 0;
      padding-left: $padding * 2;
      a {
        &:before {
          @include transform(rotate(180deg));
        }
      }
    }
    .copyright {
      border-left: 0;
      border-right: 1px solid #ffffff;
      padding-left: 0;
      padding-right: 40px;
    }
  }
}

@media (max-width: 1040px) {
  footer .wrapper {
    padding-left: 50px;
    padding-right: 50px;
  }
}
@media (max-width: 767px) {
  footer {
    margin-top: math.div($sectionMargin, 2);
    .footer_navigation {
      padding-right: 0;
      width: 100%;
      border: 0;
      margin-bottom: 20px;
      a {
        margin-bottom: math.div($padding, 2);
      }
    }
    .copyright {
      padding-left: 0;
      border-left: 0;
      width: 100%;
    }
  }
  [dir='rtl'] {
    footer {
      .footer_navigation {
        padding-left: 0;
      }
      .copyright {
        border-right: 0;
        padding-right: 0;
      }
    }
  }
}
