.footer_link {
  position: relative;
  display: block;
  height: auto;
  background-color: #ffffff;
  color: #000000;
  padding: 40px;
  margin-top: 1%;
  @include fontsize(24);

  .copy {
    margin-top: 28px;
    word-wrap: break-word;

    ol,
    ul {
      margin-left: 40px;
    }

    ol,
    ul,
    p {
      margin-bottom: 20px;
      word-wrap: break-word;
    }

    li {
      margin-bottom: 10px;
    }

    h1 {
      @include fontsize(38);
      margin-top: 40px;
      margin-bottom: 40px;
    }

    h2 {
      @include fontsize(34);
      margin-top: 40px;
      margin-bottom: 35px;
    }

    h3 {
      @include fontsize(28);
      margin-top: 40px;
      margin-bottom: 30px;
    }

    .email {
      margin-top: 40px;
      margin-bottom: 30px;
    }

    .postal {
      margin-top: 40px;
      margin-bottom: 30px;
    }
  }

  a,
  a:visited,
  a:active,
  a:focus {
    color: #ec008c;
  }
}
