a:hover {
  text-decoration: none;
}

b {
  font-weight: bold;
}

em {
  font-style: italic;
}

.c_icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'iconcomics' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
}

.icon_fullscreeen_exit:before {
  content: '\e907';
}

.icon_fullscreeen:before {
  content: '\e909';
}

.icon_expand_horizontal:before {
  content: '\e900';
}

.icon_expand_vertical:before {
  content: '\e901';
}

.icon_fat_arrow_down:before {
  content: '\e902';
}

.icon_fat_arrow_left:before {
  content: '\e903';
}

.icon_fat_arrow_right:before {
  content: '\e904';
}

.icon_fat_arrow_up:before {
  content: '\e905';
}

.icon_first:before {
  content: '\e906';
}

.icon_last:before {
  content: '\e908';
}
