@media (max-width: 1024px) {
	.in-page-ad {
		display: none;
	}
}
.in-page-ad {
	text-align: center;
	z-index: 0;
}
.in-page-ad .leaderboard {
    margin: auto;
}

.in-page-ad .full_wrapper .leaderboard {
    padding-right: 3px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
