// using #my-player id to trump the bitmovin css : specificity
//#bitmovin-player {
//  margin: auto;
//  width: 100%;
//}
//@media (min-width: 1024px) {
//  #bitmovin-player {
//    width: 65%;
//  }
//}
//
//.gameplay_page #bitmovin-player {
//  width: 60%;
//  margin: 0 20%;
//}

.bmpui-ui-controlbar .bmpui-controlbar-bottom > .bmpui-container-wrapper,
.bmpui-ui-controlbar .bmpui-controlbar-top > .bmpui-container-wrapper {
  margin: 0 0 !important;
  z-index: 10;
  position: relative;
}
.bmpui-ui-controlbar {
  z-index: 10;
}
.videoAdUiSkipButton {
  position: relative;
  z-index: 11;
}

// hide the bitmovin player watermark
.bmpui-ui-watermark,
#bitmovin-player .bmpui-ui-watermark {
  display: none;
}

.bitmovin-embed {
  padding: 0;
  margin: 0;
  position: relative;
  overflow: hidden;
  min-height: 150px;
  min-width: 260px;
  box-sizing: content-box;
  background-color: transparent;
  width: 100%;
  height: 100%;

  &:before {
    display: block;
    content: '';
    width: 100%;
    padding-bottom: 56.25%;
  }

  > iframe {
    position: absolute;
    top: 0;
    left: 0;
  }
}
