.view_counter {
  display: inline-block;
  vertical-align: top;
  @include noselect();
  @include fontsize(26);
  line-height: 1;
  background-color: rgba(0, 0, 0, 0.2);
  direction: ltr;
  border-radius: 10px;
  width: 100%;

  .icon_container,
  .count {
    position: relative;
    display: inline-block;
    vertical-align: middle;
  }
  .icon_container {
    width: 40px;
    height: 44px;
    padding: 0px 10px;

    @media only screen and (min-width: 400px) {
      width: 54px;
    }

    &.small {
      width: 40px;
    }
    .icon {
      position: absolute;
      top: 50%;
      left: 10%;
      width: 80%;
      max-height: 100%;
      @include translatecentery();
    }
    //&.small {
    //	width: 44px;
    //	}
  }
  .count {
    color: #ffffff;
    padding: 4px 12px;
    text-align: center;
    width: calc(100% - 54px);

    @media only screen and (max-width: 400px) {
      @include fontsize(16);
      width: calc(100% - 40px);
    }

    &.small {
      @include fontsize(20);
      width: 40px;
      padding: 0;
    }
  }
}

@media (max-width: 767px) {
  .view_counter {
    display: block;
    &.nowrap {
      display: inline-block;
    }
  }
}
