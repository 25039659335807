@use "sass:math";

.block-list {
  margin-top: 0;
  &:not(.margin_top) {
    .wrapper {
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
      border-top: 0;
    }
  }
  &.same_block_colour {
    .wrapper {
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      border-bottom: 0;
    }
  }
  &.block-list-filter {
    .wrapper {
      border-top: 10px solid #ffffff;
      border-radius: 20px;
    }
  }
  h2 {
    @include fontb();
    @include fontsize(44);
    //color: $color_purple;
    color: $color_white;
    line-height: 1;
    min-height: 4.4rem;
    margin-bottom: $padding;
  }
  &.block-list-filter {
    margin-top: $sectionMargin;
    .content_item {
      margin-bottom: $padding;
    }

    .title-and-filter-wrapper {
      display: flex;
      flex-wrap: nowrap;

      .sort {
        float: right;
        position: relative;
        right: 0;
        top: 0;
      }
      .cbtitle {
        flex-grow: 1;
      }
    }
  }

  &.margin_top {
    margin-top: $sectionMargin;
  }
}

@media (max-width: 767px) {
  .block-list {
    h2 {
      @include fontsize(28);
      margin-bottom: math.div($padding, 2);
    }

    &.block-list-filter {
      margin-top: math.div($sectionMargin, 2);
      .content_item {
        margin-bottom: math.div($padding, 2);
      }
    }
    &.margin_top {
      margin-top: math.div($sectionMargin, 2);
    }
  }
}
@media (max-width: 479px) {
  .block-list.block-list-filter {
    .title-and-filter-wrapper {
      display: flex;
      flex-wrap: wrap;
      .sort-wrapper {
        width: 100%;
      }
      .sort {
        position: relative;
        right: 0;
        top: 0;
        order: 1;

        &.active {
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
        }
      }
      .cbtitle {
        flex-grow: 1;
        order: 2;
      }
    }
  }
}
