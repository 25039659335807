.hero_section{
	margin-top: 0;
	.hero_image{
		position: relative;
		.buttons{
			position: absolute;
			bottom:0;
			left:0;
			width: 100%;
			padding: $padding;
			.wrapper{
				width: 100%;
			}
		}
	}
}


@media (max-width: 767px){
	.hero_section{
		.hero_image{
			.buttons{
				position: relative;
				padding: math.div($padding, 2) 0;
				.button{
					.button_inner{
						//background-color: $color_purple
					}
				}
			}
		}
	}
}
