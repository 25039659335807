.emoji__target {
  cursor: pointer;
}

.social-container {
  display: flex;
  margin-top: 10px;
  padding-top: 10px;
}

.emojis {
  display: flex;

  .like_button {
    background: none;
    border: none;
    overflow: visible;
  }
}

.emojis .total-likes {
  margin-right: 10px;
  display: flex;
  align-items: center;
}

.emojis .emojis__container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.emojis .emoji {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media (min-width: 768px) {
    margin: 0 10px;
  }

  img {
    position: relative;
    z-index: 2;

    width: 50px;
  }

  // @media (min-width: 768px) {
  //   img {
  //     width: 50px;
  //   }
  // }
  // @media (min-width: 850px) {
  //   img {
  //     width: 56px;
  //   }
  // }
  // @media (min-width: 1024px) {
  //   img {
  //     width: 75px;
  //   }
  // }

  .count {
    $fontsize: 20;
    $padding: 5;

    color: $color_purple;
    background-color: $color_white;

    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;

    padding: {
      top: $padding + px;
      right: ($padding * 3) + px;
      bottom: $padding + px;
      left: 25px;
    }

    height: $fontsize + ($padding * 2) + px;
    position: relative;
    transform: translateX(-20px);
    z-index: 1;

    @include fontb;
    @include fontsize($fontsize);
  }

  &--total {
    display: flex;

    @media (min-width: 768px) {
      display: none;
    }
    .count {
      transform: translateX(-15px);
      width: auto;
    }
  }
}

.emojis {
  .desktop-view {
    display: none;
    @media (min-width: 768px) {
      display: block;
    }

    @media (min-width: 1024px) {
      margin-top: 4px;
    }
  }

  .mobile-view {
    display: block;
    .emoji--total {
      margin-top: 10px;
    }

    .emoji-total__container {
      background-color: white;
      border-radius: 10px;
      position: relative;
      z-index: 1;
    }

    .emoji--total img {
      width: 40px;
    }

    .emoji--total .count {
      background-color: rgba(0, 0, 0, 0.2);
      display: flex;
      align-items: center;
      height: 40px;
      border-bottom-right-radius: 10px;
      border-top-right-radius: 10px;
      padding: 5px 15px 5px 25px;
      color: $color_white;
      @include fontlight;
      position: relative;
      z-index: 0;
    }

    @media (min-width: 768px) {
      display: none;
    }
  }
}

.emojis .emoji--total,
.emojis .emoji--close {
  cursor: pointer;
}

.emojis .emoji--close {
  position: absolute;
  top: 10px;
  width: 40px;
  display: none;
  z-index: 1002;
  background-color: #fff;
  border-radius: 10px;

  @media (min-width: 768px) {
    display: none !important;
  }
}

.emojis.active .emoji--close {
  display: block;
}
